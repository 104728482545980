import { createStore } from 'vuex';
import axios from 'axios';
import { filter } from './filters';
import { sequenceById } from './selection';

export default createStore({
  state: {
    searchTerm: '',
    filters: {},
    sequences: []
  },
  getters: {
    allSequences: (state) => (state.sequences),
    allFilters: (state) => (state.filters),
    searchTerm: (state) => (state.searchTerm),
    filteredSequences: (state) => (filter(state)),
    sequenceById: (state) => ((id) => (sequenceById(state, id)))
  },
  mutations: {
    toggleFilter: (state, filter) => (state.filters[filter.property][filter.attribute].value = !(state.filters[filter.property][filter.attribute].value)),
    updateSearchTerm: (state, term) => (state.searchTerm = term),
    updateSequencesList: (state, sequences) => (state.sequences = sequences),
    updateFilterList: (state, filters) => (state.filters = filters)
  },
  actions: {
    toggleFilter({ commit }, filter) {
      commit('toggleFilter', filter);
    },
    updateSearchTerm({ commit }, term) {
      commit('updateSearchTerm', term);
    },
    getSequences({ commit }) {
      axios.get('https://raw.githubusercontent.com/jabramo02/Sequence-Finder-Data/master/sequences.json')
        .then(response => {
          commit('updateSequencesList', response.data)
        })
    },
    getFilters({ commit }) {
      axios.get('https://raw.githubusercontent.com/jabramo02/Sequence-Finder-Data/master/filters.json')
        .then(response => {
          commit('updateFilterList', response.data)
        })
    },

  }
})
