import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'Browser-View',
    component: () => import('../views/Browser-View.vue')
  },
  {
    path: '/sequence/:id',
    name: 'Sequence-View',
    props: true,
    component: () => import('../views/Sequence-View.vue')
  },
  {
    path: '/about',
    name: 'About-View',
    component: () => import('../views/About-View.vue')
  },
  {
    path: '/submit-shared-sequence',
    name: 'About-View',
    component: () => import('../views/Shared-Sequence-View.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404-View',
    component: () => import('../views/404-View.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
