<template>
  <div class="app">
    <Nav/>
    <Main/>
    <Footer/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Main from './components/Main.vue';
import Nav from './components/Nav.vue';
import Footer from './components/Footer.vue';

export default defineComponent({
  name: 'App',
  components: {
    Main,
    Nav,
    Footer
  }, 

});
</script>

<style>
body {
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: min-content auto min-content;
}
</style>
