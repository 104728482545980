<template>
  <div class="nav">
    <router-link to="/"><img src="@/assets/sequence-finder-logo.svg" alt="sequence-finder-logo"></router-link>
    <Search-Bar/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import SearchBar from './Search-Bar.vue';

export default defineComponent({
  components: { SearchBar },
  name: 'Nav',
});
</script>

<style scoped>
.nav {
  background-color: #42b983;
  height: min-content;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
@media screen and (max-width: 450px){
.nav {
  grid-template-columns: auto;
  grid-row: auto auto;
  justify-content: center;
}
a {
  text-align:center;
}
}
  
img{
  height: 50px;
  padding: 10px;
}
.search-bar {
text-align: right;
padding: 10px;
}
</style>