function getActiveFilters(state) {
    let stateFilters = state.filters
    let activeFilters = {};
    for (let property of Object.keys(stateFilters)) {
        activeFilters[property] = [];
        for (let attribute of stateFilters[property]) {
            if (attribute.value == true) {
                activeFilters[property].unshift(attribute['attribute'])
            }
        }
    }
    return activeFilters;
}

function sequenceFitsFilters(sequenceFilterableAttribuites, activeFilters) {
    for (let property of Object.keys(activeFilters)) {
        if (activeFilters[property].length != 0) {
            let propertyPass = false;
            for (let attribute of activeFilters[property]) {
                if (sequenceFilterableAttribuites[property].includes(attribute)) {
                    propertyPass = true;
                }
            }
            if (!propertyPass) { return false; }
        }
    }
    return true;
}

function filterOnProperties(state) {
    let activeFilters = getActiveFilters(state);
    let filteredList = [];
    for (let seq of state.sequences) {
        if (sequenceFitsFilters(seq.filterable, activeFilters)) {
            filteredList.unshift(seq);
        }
    }
    return filteredList;
}

function filterBySearch(state, filteredSeqList) {
    let term = (state.searchTerm).toUpperCase();
    let outputSeqList = []
    for (let seq of filteredSeqList) {
        let searchableField = [seq.title.toUpperCase(), seq.artist.toUpperCase()];
        for (let attribute of searchableField) {
            if (attribute.includes(term)) {
                outputSeqList.unshift(seq);
                break;
            }
        }
    }
    return outputSeqList;

}

export function filter(state) {
    return filterBySearch(state, filterOnProperties(state));
}