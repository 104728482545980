<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-card">
        <h1>Resources</h1>
        <ul>
          <li><a href="https://www.xlights.org">xLights</a></li>
        </ul>
      </div>
      <div class="footer-card">
        <h1>Information</h1>
        <ul>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/faq">FAQ</router-link></li>
        </ul>
      </div>
      <div class="footer-card">
        <h1>Contact Us</h1>
        <ul>
          <li><a>example@example.com</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<style scoped>
.footer {
  background-color: #42b983;
  height: min-content;
  padding: 10px;
}
.footer-container{
  display: grid;
  max-width: 1600px;
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  

}
.footer-card {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
a {
  text-decoration: none;
  color: inherit;
}
h1 {
  font-size: 20pt;
  margin: 10px;
  text-align: center;
}
ul {
  list-style: none;
  padding-left: 12px;
}
li {
  padding: 3px;
}
@media screen and (max-width: 920px) {
  .footer-container{
    grid-template-columns: 1fr;
  }
}
</style>