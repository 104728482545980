<template>
  <div class="main">
    <router-view/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'Main',
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main{
  padding: 10px;
}

</style>
