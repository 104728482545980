<template>
  <div class="search-bar">
    <form @submit="updateSearchOnSubmit">
      <input type="text" placeholder="Search.." name="search" v-model="term"/>
      <button type="submit"><img src="@/assets/search.svg" alt="search-icon"/></button>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "Search-Bar",
  data(){
    return {
      term: (this.searchTerm)
    }
  },
  methods: {
    ...mapActions(['updateSearchTerm']),
    updateSearchOnSubmit(e) {
      e.preventDefault();
      this.updateSearchTerm(this.term)
    },
  },
  computed: {
    ...mapGetters(["searchTerm"]),
  },
});
</script>

<style scoped>
input {
  padding: 6px;
}
button {
  float: right;
  padding: 6px 10px;
  background: #2c3e50;
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-radius: 0px;
}
button:hover {
  background: #415568;
}
</style>